import {
  cloneElement,
  createContext,
  memo,
  ReactElement,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { MenuButton, MenuStateReturn, useMenuState } from 'reakit/Menu'

import { Link, LinkProps } from '../link'
import { Menu, MenuIconWrapper, MenuItem, MenuItemContent } from './style'

type Props = {
  button: ReactElement
  items: ReactElement[]
}

const MenuContext = createContext<MenuStateReturn | undefined>(undefined)

export const PopoverMenu = ({ button, items }: Props) => {
  const menu = useMenuState({ gutter: 8, unstable_fixed: true })
  const value = useMemo(() => menu, Object.values(menu))

  const disclosureRef = useRef<HTMLElement>(null)

  // TODO: refactor "as any"
  return (
    <MenuContext.Provider value={value}>
      <MenuButton
        as={'button'}
        {...menu}
        unstable_disclosureRef={disclosureRef}
      >
        {((props: any) => cloneElement(button, { buttonProps: props })) as any}
      </MenuButton>
      <Menu
        {...menu}
        aria-label="Actions"
        style={{ minWidth: disclosureRef.current?.offsetWidth }}
      >
        {items}
      </Menu>
    </MenuContext.Provider>
  )
}

type ItemProps = { label: string; icon?: any; filter?: any } & (
  | {
      onClick: () => void
      selected?: boolean
      disabled?: boolean
      closeOnSelect?: boolean
    }
  | {
      href: LinkProps['href']
      onClick: () => void
      closeOnSelect?: boolean
      selected?: boolean
      disabled?: boolean
    }
)

const Item = memo((props: ItemProps) => {
  const menu = useContext(MenuContext)

  if (!menu) {
    throw new Error(
      'ButtonMore.Item must be rendered inside ButtonMore component'
    )
  }
  const { selected, closeOnSelect = true } = props

  if ('href' in props && props.href) {
    return (
      <Link href={props.href} shallow query={props?.filter ?? ''}>
        <MenuItem
          as="a"
          aria-selected={selected}
          {...menu}
          onClick={() => {
            menu.hide()
            props.onClick()
          }}
        >
          <MenuItemContent>
            {props?.icon ? (
              <>
                <MenuIconWrapper>{props.icon}</MenuIconWrapper> {props.label}
              </>
            ) : (
              <>{props.label}</>
            )}
          </MenuItemContent>
        </MenuItem>
      </Link>
    )
  }

  return (
    <MenuItem
      {...menu}
      type="button"
      aria-selected={selected}
      onClick={() => {
        closeOnSelect && menu.hide()
        props.onClick()
      }}
    >
      <MenuItemContent>
        {props?.icon ? (
          <>
            <MenuIconWrapper>{props.icon}</MenuIconWrapper> {props.label}
          </>
        ) : (
          <>{props.label}</>
        )}
      </MenuItemContent>
    </MenuItem>
  )
})

PopoverMenu.Item = Item

import { FastFieldProps, Field as FormikField } from 'formik'
import { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react'

import { Field, FieldProps } from '../field'
import { Input } from './style'

type InputProps = {
  type?: string
  placeholder?: string
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  disabled?: boolean
  readOnly?: boolean
  large?: boolean
  autoComplete?: string
  autoFocus?: boolean
}

type Props = FieldProps & InputProps

export const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    disabled,
    readOnly,
    large,
    autoComplete = 'off',
    autoFocus,
    ...field
  } = props

  return (
    <Field name={name} {...field}>
      <Input
        ref={ref}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        large={large}
        autoComplete={autoComplete}
        autoCapitalize="off"
        autoCorrect="off"
        autoFocus={autoFocus}
        spellCheck={false}
        readOnly={readOnly}
        aria-invalid={!!field.error}
      />
    </Field>
  )
})

type TextInputFieldProps = Omit<Props, 'value' | 'onChange' | 'onBlur'> & {
  name: string
}

export const TextInputField = forwardRef<HTMLInputElement, TextInputFieldProps>(
  ({ name, ...rest }, ref) => {
    return (
      <FormikField name={name}>
        {({ field, meta }: FastFieldProps) => (
          <TextInput
            ref={ref}
            error={meta.touched && meta.error ? meta.error : undefined}
            {...field}
            {...rest}
          />
        )}
      </FormikField>
    )
  }
)

import { Menu as ReakitMenu, MenuItem as ReakitMenuItem } from 'reakit/Menu'
import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { media } from '~/styles/media'

export const Menu = styled(ReakitMenu)({
  background: colors.white,
  border: `1px solid ${colors.whiteFog}`,
  boxShadow: '0px 4px 20px rgba(47, 47, 75, 0.08)',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  padding: '4px 0',
  maxHeight: 400,
  overflow: 'auto',
  zIndex: 999,

  [media.maxSmall]: {
    padding: 0,
    borderRadius: 5,
    borderBottom: `5px ${colors.light}`,
  },
})

export const MenuItem = styled(ReakitMenuItem)({
  padding: '12px 20px 8px',
  fontSize: '0.875rem',
  lineHeight: 1.7,
  color: colors.dark,
  textAlign: 'left',
  flexShrink: 0,
  zIndex: 999,

  '&:not(:last-child)': {
    borderBottom: `1px solid ${colors.light} !important`,
  },

  '&:hover': {
    background: colors.soft,
  },

  '&[aria-selected="true"]': {
    color: '#2F2F4B',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: colors.light,
  },
})

export const MenuItemContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '1rem',
})

export const MenuIconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '1.25rem',
  height: '1.25rem',
})

import { Button as ReakitButton } from 'reakit/Button'
import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'
import { input } from '~/styles/input'

export const Button = styled(ReakitButton)({
  ...input.base,
  '&:hover': {
    ...input.hover,
  },

  '&[aria-expanded="true"]': {
    fontWeight: fonts.text.medium,
    ...input.active,
    // svg: {
    //   transform: 'rotate(180deg)',
    // },
  },

  "&[aria-invalid='true']": {
    borderColor: colors.error,
    boxShadow: `0 0 0 1px ${colors.error} inset`,
  },

  "&[aria-disabled='true']": {
    color: colors.slateGray,
    borderColor: colors.whiteFog,
    background: colors.soft,
  },

  svg: {
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translateY(-50%)',
  },
})

import { Field, FieldProps } from '../field'
import { Button } from './style'
import IconDropdown from './assets/icon-dropdown.svg'

type Props = FieldProps & {
  children: React.ReactNode
  buttonProps?: any
  disabled?: boolean
  icon?: React.ReactNode
}

export const MenuButton = (props: Props) => {
  const {
    children,
    buttonProps,
    disabled,
    icon = (
      <IconDropdown
        css={{
          position: 'absolute',
          right: 15,
          top: '52%',
          transform: 'translateY(-50%)',
          transition: 'all .1s',
          transformOrigin: '5px 2px',
          '[aria-expanded="true"] &': {
            transform: 'translateY(-50%) rotate(180deg)',
          },
        }}
      />
    ),
    ...field
  } = props

  return (
    <Field {...field}>
      <Button {...buttonProps} disabled={disabled} aria-invalid={!!field.error}>
        {children}
        {icon}
      </Button>
    </Field>
  )
}

import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

import { Meta } from '../typography'

export const Optional = styled(Meta)({
  color: `${colors.slateGray} !important`,
  position: 'absolute',
  top: 0,
  right: 0,
})

export const Error = styled(Meta)({
  color: `${colors.error} !important`,
  marginTop: 4,
  textAlign: 'left',
})

export const Addon = styled.div({
  fontSize: '0.875rem',
  fontWeight: fonts.text.regular,
  background: colors.soft,
  border: colors.border,
  borderRight: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '2px 0 0 2px',
  padding: '0 16px',
  whiteSpace: 'nowrap',
  color: colors.dark,

  '~ input': {
    borderRadius: '0 2px 2px 0',
  },
})

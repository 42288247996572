import type { CSSObject } from 'styled-components'

import { fonts } from './fonts'
import { colors } from './colors'

const hover: CSSObject = {
  borderColor: colors.slateGray,
}

export type ActiveProps = {
  active: boolean
}

const active: CSSObject = {
  borderColor: colors.dark,
  boxShadow: `0 0 0 1px ${colors.dark} inset`,
}

const base: CSSObject = {
  display: 'block',
  width: '100%',
  position: 'relative',
  fontSize: '0.875rem',
  fontWeight: fonts.text.regular,
  color: colors.dark,
  textAlign: 'left',
  borderRadius: 2,
  background: colors.white,
  border: colors.border,
  height: 48,
  padding: '0 20px',

  ':hover': hover,
}

export const input = {
  base,
  hover,
  active,
}

import { GridColumnProps, SpaceProps } from 'styled-system'

import { Box, Flex } from '../layout'
import { Meta } from '../typography'
import { Addon, Error, Optional } from './style'

export type FieldProps = SpaceProps &
  GridColumnProps & {
    label?: string
    name?: string
    error?: string
    optional?: boolean
    addon?: string
    icon?: any
  }

type Props = FieldProps & {
  children: React.ReactNode
}

export const Field = ({
  label,
  name,
  error,
  optional,
  addon,
  children,
  ...space
}: Props) => {
  return (
    <Box flex={1} position="relative" {...space}>
      {label && (
        <Meta
          as="label"
          htmlFor={name}
          marginBottom={4}
          style={{ display: 'block', textAlign: 'left' }}
        >
          {label}
        </Meta>
      )}
      {optional && <Optional>optional</Optional>}

      {addon ? (
        <Flex>
          <Addon>{addon}</Addon>
          {children}
        </Flex>
      ) : (
        children
      )}

      {error && <Error>{error}</Error>}
    </Box>
  )
}

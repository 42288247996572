import styled from 'styled-components'

import { MenuButton } from '../menu-button'

export const SelectMenuButton = styled(MenuButton)<{
  isIconStatic?: boolean
  large?: boolean
}>(props => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: props.large ? '17px 40px 15px 20px' : '0 40px 0 18px',
    height: props.large ? '56.4px' : '48px',
  },

  '[aria-expanded="true"]': {
    boxShadow: '0 0 0 2px ',
  },

  '[aria-expanded="true"] svg': {
    transform: `translateY(-50%) rotate(${
      props?.isIconStatic ? '0deg' : '180deg'
    })`,
  },

  svg: {
    position: 'absolute',
    right: 15,
    top: '52%',
    transform: 'translateY(-50%)',
    transition: 'all .1s',
    transformOrigin: '5px 2px',
  },
}))

export const ContentWrapper = styled.div({
  display: 'flex',
  position: 'inherit',
  alignItems: 'center',
})

export const LabelWrapper = styled.div({
  position: 'relative',
  left: '2.3rem',
})

export const IconWrapper = styled.div({
  position: 'absolute',
  left: '1rem',
  width: '1.25rem',
  height: '1.25rem',

  svg: {
    transform: 'translateY(-50%) rotate(0) !important',
  },
})

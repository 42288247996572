import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

export const Input = styled.input<{ large?: boolean }>(
  {
    display: 'block',
    width: '100%',
    background: colors.white,
    borderRadius: 2,
    border: colors.border,
    fontSize: '0.875rem',
    lineHeight: 1.6,
    fontWeight: fonts.text.regular,
    color: colors.dark,
    padding: '13px 18px 11px',
    outline: 0,

    textOverflow: 'ellipsis',

    '&:hover': {
      borderColor: colors.slateGray,
    },

    '&:focus': {
      borderColor: colors.dark,
      boxShadow: `0 0 0 1px ${colors.dark} inset`,
    },

    '&:placeholder': {
      color: colors.slateGray,
      fontWeight: fonts.text.regular,
    },

    "&[aria-invalid='true']": {
      borderColor: colors.error,
      boxShadow: `0 0 0 1px ${colors.error} inset`,
    },

    '&:disabled': {
      background: colors.soft,
      color: colors.slateGray,
    },
  },
  props =>
    props.large && {
      padding: '17px 18px 15px',
    }
)
